<template>
  <div>
 industrieeee
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.exp-panels {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.image-examples {
  display: flex;
  flex-direction: row;
}

.spacer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.request {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.has-margin-top {
  margin-top: 1rem;
}
</style>